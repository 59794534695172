import {
    SimpleShowLayout,
    ReferenceManyField,
    SingleFieldList,
    TextField,
    BooleanField, Show,
} from "react-admin";

import {JsonField} from "react-admin-json-view";

import OrganizationLink from "../Common/OrganizationLink";


export const PricingGroupShow = () => {
  return (
  <Show >
      <SimpleShowLayout>
        <ReferenceManyField
            source="organizationKey"
            target="key"
            reference="organization-details"
            label="Organization"
        >
            <SingleFieldList>
                <OrganizationLink>
                        <TextField
                            source="name"
                            label="Organization"
                            sx={{ margin: "5px 0px 15px", display: "inline-flex" }}
                        />
                </OrganizationLink>
            </SingleFieldList>
        </ReferenceManyField>
      <ReferenceManyField
        source="productionId"
        target="id"
        reference="productions"
        label="Production"
      >
        <SingleFieldList>
            <TextField
              source="productionName"
              label="Production"
              sx={{ margin: "5px 0px 15px", display: "inline-flex" }}
            />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="name" />
      <TextField source="ceilingPrice" />
      <TextField source="floorPrice" />

      <JsonField
          source="pricingGroupTickets"
          label = "Pricing Group Tickets"
          jsonString={false}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
          
        />
      <JsonField
        source="ruleSet"
        jsonString={false}
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <JsonField
        source="marketGroupCriteria"
        jsonString={false}
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <BooleanField source="isAutoPricingEnabled" />

      </SimpleShowLayout>
  </Show>
  )
      };
