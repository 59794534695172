import React, { useEffect, useState } from 'react';
import { useDataProvider, SelectInput, SelectInputProps } from 'react-admin';
import { FieldProps } from 'react-admin';
import Divider from '@mui/material/Divider';

interface Organization {
    id: string;
    name: string;
    status: string;
    organizationKey?: string;
}

interface CustomOrganizationInputProps {
    source?: string;
    label?: string;
    resourceToFetch?: string;
}

const CustomOrganizationInput: React.FC<FieldProps<Organization> & SelectInputProps & CustomOrganizationInputProps> = (
{
       // Destructure and assign defaults here:
       source = 'organizationId',
       label = 'Organization',
        resourceToFetch = 'organization-details',
       ...props
   }) => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState<Organization[]>([]);

    useEffect(() => {
        dataProvider.getList<Organization>(resourceToFetch, {
            pagination: { page: 1, perPage: 99999 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        })
            .then(({ data }) => {
                // Sort the data: Active first, then others  
                const active = data.filter(org => org.status === 'Active').sort((a, b) => a.name.localeCompare(b.name));
                const others = data.filter(org => org.status !== 'Active').sort((a, b) => a.name.localeCompare(b.name));
                const divider = { id: 'divider', name: '', status: 'divider' };

                setChoices([...active, divider, ...others]);
            })
            .catch(error => {
                console.error('Error fetching organization details:', error);
            });
    }, [dataProvider]);

    const optionRenderer = (choice:any) => {
        if (choice.status === 'divider') {
            return <Divider component="li" sx={{width:"100%", borderWidth: "thin", borderColor: "rgba(0, 0, 0, 1)"}} />;
        }
        return (
            <div>
                {`${choice.name}`}
            </div>
        );
    };

    const enhancedChoices = [
        ...choices.map(org => ({ id: source == "organizationKey" ? org.organizationKey : org.id, name: `${org.name} (${org.status})`, status: org.status, disabled: org.status === 'divider' })),
    ];

    return (
        <SelectInput
            {...props}
            choices={enhancedChoices}
            optionText={(choice) => optionRenderer(choice)}
            source={source}
            label={label}
        />
    );
};

export default CustomOrganizationInput;  
