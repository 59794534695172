import {
  Edit,
  TextInput,
  SelectInput,
  minLength,
  TabbedForm,
  FormTab,
  SaveButton,
  DeleteButton,
  Toolbar, AutocompleteArrayInput, ReferenceArrayInput,
} from "react-admin";
import { organizationStatusChoices } from "./common";
import {
  comparableMarketplaceSourceChoices,
  countryChoices,
  phoneNumberFormat,
  stateChoices,
} from "../../utils/constants";
import { email } from "ra-core";
import { EntryListComponent } from "./POS/PosList";
import { CancelButton } from "../CustomToolbar/CancelButton";
import { MarketplaceListComponent } from "./Marketplace/MarketplaceList";
import React from "react";

export const OrganizationEdit = (props: any) => (
  <Edit mutationMode="pessimistic" {...props}>
    <TabbedForm toolbar={<></>}>
      <FormTab label="Organization info">
      <TextInput source="organizationKey" disabled/>
        <TextInput source="name" required />
        <TextInput source="knownAs" />
        <TextInput source="primaryContact" />
        <TextInput source="email" validate={email()} required />
        <TextInput
          source="phone"
          parse={phoneNumberFormat}
          validate={minLength(14)}
          required
        />
        <TextInput source="address1" />
        <TextInput source="address2" />
        <TextInput source="city" required />
        <SelectInput source="state" choices={stateChoices} required />
        <TextInput source="postalCode" required />
        <SelectInput
          source="country"
          choices={countryChoices}
          defaultValue="United States"
          required
        />
        <SelectInput
          source="status"
          choices={organizationStatusChoices}
          required
        />
        <SelectInput
        source="comparableMarketplaceSource"
        choices={comparableMarketplaceSourceChoices}
        required
      />
      <TextInput source="externalTenantType" disabled/>
        <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
        <ReferenceArrayInput source="featureIds" reference="features">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <Toolbar sx={{width:'100%'}}>
          <div>
            <SaveButton />
            <CancelButton />
          </div>
            <div style={{ marginLeft: 'auto', marginRight: '0px' }}>
            <DeleteButton />
          </div>   
        </Toolbar>
      </FormTab>
      <FormTab label="POS Settings">
        <EntryListComponent />
      </FormTab>

      <FormTab label="Marketplace Settings">
        <MarketplaceListComponent />
      </FormTab>
    </TabbedForm>
  </Edit>
);
