import * as React from 'react';
import { useMemo } from 'react';
import {
    useInput,
    FieldTitle,
    useTranslate
} from 'react-admin';
import {
    Box,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    SelectChangeEvent,
    Typography
} from '@mui/material';

interface MonthYearFilterInputProps {
    source: string;
    label?: string;
    minYear?: number;
    maxYear?: number;
    alwaysOn?: boolean;
}

export const MonthYearFilterInput: React.FC<MonthYearFilterInputProps> = (props) => {
    const {
        source,
        label,
        minYear = 2000,
        maxYear = 2050,
        ...rest
    } = props;

    const {
        field: { name, value, onChange },
        fieldState: { error, isTouched },
        isRequired,
    } = useInput({ source, ...rest });

    const translate = useTranslate();

    // Split "YYYY-MM"
    const [currentYear, currentMonth] = useMemo(() => {
        if (typeof value === 'string' && value.includes('-')) {
            const [y, m] = value.split('-');
            return [y, m];
        }
        return ['', ''];
    }, [value]);

    // Generate arrays for months [1..12] and years [minYear..maxYear]
    const months = React.useMemo(() => Array.from({ length: 12 }, (_, i) => i + 1), []);
    const years = React.useMemo(
        () => Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i),
        [minYear, maxYear]
    );

    const handleMonthChange = (event: SelectChangeEvent) => {
        const newMonth = event.target.value;
        onChange(`${currentYear || '2020'}-${newMonth.padStart(2, '0')}`);
    };

    const handleYearChange = (event: SelectChangeEvent) => {
        const newYear = event.target.value;
        onChange(`${newYear}-${currentMonth ? currentMonth.padStart(2, '0') : '01'}`);
    };

    return (
        <Paper
            elevation={2}                // A slight shadow
            sx={{ p: 2, mt:1, mb:1 }}         // Padding & margin-bottom
        >
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                <FieldTitle
                    label={label}
                    source={source}
                    isRequired={isRequired}
                />
            </Typography>

            <Box display="flex" gap={2}>
                {/* Year Selector */}
                <FormControl size="small" sx={{ minWidth: 100 }} error={Boolean(isTouched && error)}>
                    <InputLabel id={`${name}-year-label`}>
                        {translate('Year', { _: 'Year' })}
                    </InputLabel>
                    <Select
                        sx={{ maxWidth: 100 }}
                        labelId={`${name}-year-label`}
                        id={`${name}-year`}
                        label={translate('Year', { _: 'Year' })}
                        value={currentYear || ''}
                        onChange={handleYearChange}
                    >
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Month Selector */}
                <FormControl size="small" sx={{ minWidth: 100 }} error={Boolean(isTouched && error)}>
                    <InputLabel id={`${name}-month-label`}>
                        {translate('Month', { _: 'Month' })}
                    </InputLabel>
                    <Select
                        sx={{ maxWidth: 100 }}
                        labelId={`${name}-month-label`}
                        id={`${name}-month`}
                        label={translate('Month', { _: 'Month' })}
                        value={currentMonth || ''}
                        onChange={handleMonthChange}
                    >
                        {months.map((m) => {
                            const mm = String(m).padStart(2, '0');
                            return (
                                <MenuItem key={mm} value={mm}>
                                    {mm}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>

            {/* Error message */}
            {isTouched && error && (
                <FormHelperText error sx={{ mt: 1 }}>
                    {error.message}
                </FormHelperText>
            )}
        </Paper>
    );
};
