import {
  Datagrid,
  List,
  TextField,
  TextInput,
  BooleanField,
  SelectInput,
  useRecordContext,
  useDataProvider,
  
} from "react-admin";
import { sourceTypeChoices } from "../Organizations/common";
import { CustomOrganizationReferenceInput } from "../Organizations/CustomOrganizationReferenceInput";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrganizationReferenceField from "../Organizations/OrganizationReferenceField";
import { inventoryStatusChoices } from "./common";
import {Typography} from "@mui/material";
interface ProductionFieldProps {
  record?: Record<string, any>;
  source?: string;
}
const ProductionField: FC<ProductionFieldProps> = ({ source }) => {
  const record = useRecordContext();
  return record.productionId ? (
    <Link to={`/productions/?filter=${JSON.stringify({
      id: [record.productionId],
    })}`}>
      {`${record.productionId} - ${record.productionName}`}
    </Link>
  ) : record.productionName ? <Typography>{record.productionName}</Typography> : null;
};


const inventoryFilter = [
  <CustomOrganizationReferenceInput
    source="organizationKey"
    label="Organization"
    alwaysOn
  />,
  <TextInput source="sourceProductionKey" />,
  <TextInput source="productionName" />,
  <TextInput source="venueName" />,
  <SelectInput source="sourceType" choices={sourceTypeChoices} />,
  <SelectInput source="inventoryStatus" choices={inventoryStatusChoices} />,

];

export const InventoryList = () => {
  const [organizations, setOrganizations] = useState<any[]>([]);
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider
      .getList("organizations", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setOrganizations(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataProvider]);

  return (
    <List filters={inventoryFilter}>
      <Datagrid>
        <TextField source="id" />
        <OrganizationReferenceField organizations={organizations} source="Organization" />
        <ProductionField source="production"/>
        <TextField source="venueName" />
        <TextField source="section" />
        <TextField source="row" />
        <TextField source="quantity" />
        <TextField source="startSeat" sortable={false}/>
        <TextField source="endSeat" sortable={false}/>
        <TextField source="stockType" />
        <TextField source="seatType" sortable={false}/>
        <TextField source="costPrice" sortable={false}/>
        <TextField source="listPrice" />
        <TextField source="soldPrice" sortable={false}/>
        <TextField source="splitRule" sortable={false}/>
        <BooleanField source="isInHand" />
        <BooleanField source="isParking" />
        <BooleanField source="isShared" sortable={false}/>
        <TextField source="inventoryStatus" sortable={false}/>
        <TextField source="validSplits" sortable={false}/>
        <TextField source="externalNotes" sortable={false}/>
        <TextField source="sourceKey" sortable={false}/>
        <TextField source="sourceProductionKey" sortable={false}/>
        <TextField source="sourceType" sortable={false}/>
        <TextField source="inHandDate" />
        <TextField source="internalNotes" sortable={false}/>
        <TextField source="attributes" sortable={false}/>
        <TextField source="createdDate" />
        <TextField source="lastModifiedBy" sortable={false}/>
        <TextField source="lastModifiedDate" />
      </Datagrid>
    </List>
  );
};
