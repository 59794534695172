import {
    Datagrid,
    List,
    TextField,
    useRecordContext,
    DeleteButton,
    DateField,
    Button,
    useDataProvider,
    useRefresh,
    useNotify,
    HttpError,
    EditButton,
    Link,
    FunctionField,
} from "react-admin";
import DoneIcon from "@mui/icons-material/Done";
import { FC } from "react";

export const ScheduleInstanceList = () => {

    const SendJobInit = () => {
        const record = useRecordContext();
        const dataProvider = useDataProvider();
        const refresh = useRefresh();
        const notify = useNotify();

        const handleClick = async () => {
            try {
                await dataProvider.initializeScheduleJob({ data: record });
                notify(`Initialize request sent`, { type: "success" });
                refresh();
            } catch (error) {
                if (error instanceof HttpError) {
                    console.error("Error sending initialize request:", error);
                    notify("Error initializing job", { type: "error" });
                }
            }
        };
        return (
            <Button
                label="Initialize"
                onClick={handleClick}                
            >
                <DoneIcon />
            </Button>
        );
    };

    const PauseResumeButton = () => {
        const record = useRecordContext();
        const dataProvider = useDataProvider();
        
        const refresh = useRefresh();
        const notify = useNotify();

        const handleClick = async () => {
            try {
                const action = record.isPaused ? 'Resume' : 'Pause';
                const data = { id: record.id, action: action };
                await dataProvider.instancePauseResume({ data });
                notify(`${action} request sent`, { type: "success" });
                refresh();
            } catch (error) {
                if (error instanceof HttpError) {
                    console.error("Error sending initialize request:", error);
                    notify("Error initializing job", { type: "error" });
                }
            }
        };
        return (
            <Button
                label={record.isPaused ? "Resume" : "Pause"}
                onClick={handleClick}                
            >
                <DoneIcon />
            </Button>
        );
    };

    const GoToSchedulesButton = () => {
        return (
            <Link
                to={{
                    pathname: `/schedules`
                }}
                onClick={(event) => event.stopPropagation()}
            >
                Go back to Schedules
            </Link>
        );
    };

    const IntervalField: FC<any> = ({label}) => {
        const record = useRecordContext();
        
        return record.scheduleTime ?<TextField source="scheduleTime" sx={{display: 'inline-block',minWidth: 'max-content'}}/> :
        <FunctionField sx={{display: 'inline-block',minWidth: 'max-content'}} render={(r:any) => `${r.defaultScheduleTime} (Default)`} />
      };
    
    const PosMarketplaceNameField: FC<any> = ({label}) => {
        const record = useRecordContext();
        return record.organizationMarketplaceId ? 
            <TextField source="organizationMarketplaceName" label="Organization Marketplace Name"/> 
            : <TextField source="posOrganizationName" label="POS Organization Name"/>;
    }
    return (
        <List actions={<GoToSchedulesButton/>}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" />               
                <TextField source="organizationKey" />
                <PosMarketplaceNameField label="Pos/Marketplace Organization Name" />
                <TextField source="schedulerDefinitionName" />
                <TextField source="schedulerInstanceJobKey" />
                <TextField source="sourceKey" />
                <IntervalField label="Interval" />
                <DateField source="lastRequestDateTime" showTime/>
                <DateField source="nextEstimatedRequestDateTime" showTime/>
                <SendJobInit />
                <PauseResumeButton />
                <EditButton />
                <DeleteButton></DeleteButton>                
            </Datagrid>
        </List>)
}