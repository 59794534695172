import {FaMoneyBill} from "react-icons/fa";
import {BillingTransactionsList} from "../components/BillingTransactions/BillingTransactionsList";

const BillingTransactions = {
    name: "billing-transactions",
    icon: FaMoneyBill,
    list: BillingTransactionsList,
    menuLabel: "Billing Transactions"
};

export default BillingTransactions;