import {useRecordContext, useResourceContext } from "react-admin";
import {getEnvironment} from "../../utils/helpers";
import {useMsal} from "@azure/msal-react";
import {useEffect, useState} from "react";
import {extractToken} from "../../utils/general";
import {jwtDecode} from "jwt-decode";


const Impersonate: React.FC = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState<string>("");
    const [objectId, setObjectId] = useState<string>("");
    

    const isSkybox = () => {
        var str = record["organizationKey"];
        return str?.toLowerCase().startsWith("sb-");
    }

    useEffect(
        () => {
            const account = accounts[0];
            extractToken(account, instance).then((resp) => {
                setToken(resp);
            });
        },
        []
    );

    useEffect(() => {
        if (record && objectId === "") {
            resource == "organizations" ? setObjectId(record.adminObjectId) : setObjectId(record.objectId);
        }
    }, []);

    const impersonateActive = () => {
        if (!token || !objectId || (record.status !== "Active")) {
            return false;
        }
        const decoded: any = jwtDecode(token);
        return decoded.isMemberOfImpersonationGroup;
    }
    
    const environmentString = getEnvironment() ? `.${getEnvironment()?.toLowerCase()}` : "";
    const b2cHostName = process.env.REACT_APP_B2C_HOSTNAME;
    const b2cTenant = process.env.REACT_APP_B2C_TENANT_NAME;
    const impersonationBaseUrl = `https://${b2cHostName}/${b2cTenant}/oauth2/v2.0/authorize`;
    const clientId = isSkybox() ? process.env.REACT_APP_DRIVE_B2C_APP_CLIENT_ID : process.env.REACT_APP_MEMBERS_B2C_APP_CLIENT_ID;
    const siteType = isSkybox() ? "skyboxdrive" : "pricerqx";
    
    const redirectUri = `https://members${environmentString}.${siteType}.com/impersonate`;
    const impersonationUrl = `${impersonationBaseUrl}?p=B2C_1A_SignIn_Impersonation&client_id=${clientId}&response_type=id_token&redirect_uri=${redirectUri}&scope=openid&response_mode=fragment&id_token_hint=${token}&impersonatedUserObjectId=${objectId}`;

    return (
        impersonateActive() &&
        <a
            href={impersonationUrl}
            target={"_blank"}
        >
            Impersonate
        </a>
    );
};

export default Impersonate;