import { FaLink } from "react-icons/fa";
import { MailboxAssignmentList } from "../components/MailboxAssignments/MailboxAssignmentList";
import MailboxAssignmentCreate from "../components/MailboxAssignments/MailboxAssignmentCreate";

const MailboxAssignments = {
    name: "mailbox-assignments",
    icon: FaLink,
    list: MailboxAssignmentList,
    create: MailboxAssignmentCreate,
    menuLabel: "Mailbox Assignments",
};

export default MailboxAssignments;