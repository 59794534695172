import { Create, SimpleForm, TextInput, Toolbar, SaveButton } from 'react-admin';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {CustomOrganizationReferenceInput} from "../Organizations/CustomOrganizationReferenceInput";

const CustomToolbar = () => {
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate(-1); // Navigate to the previous page
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button onClick={handleCancel} style={{ marginLeft: '8px' }}>
                Cancel
            </Button>
        </Toolbar>
    );
};

const MailboxAssignmentCreate = () => (
    <Create>
        <SimpleForm toolbar={<CustomToolbar />}>
            <CustomOrganizationReferenceInput
                source="organizationKey"
                label="Organization"
            />
            <TextInput
                source="emailaddress"
                required
            />
        </SimpleForm>
    </Create>
);

export default MailboxAssignmentCreate;