import { Datagrid, List, TextField} from "react-admin";

export const MailboxAssignmentList = () => (
    <List
        sort={{ field: "organizationKey", order: "ASC" }}>
        <Datagrid>
            <TextField source="subscriptionId" />
            <TextField source="organizationKey" />
            <TextField source="organizationName" />
            <TextField source="emailAddress" />
            <TextField source="expiration" />
        </Datagrid>
    </List>
);