import { useEffect, useState } from "react";
import { AutocompleteInput, Loading, useDataProvider } from "react-admin";

interface RecordWithKey {
  id: string;
  organizationKey: string;
  name: string;
  [key: string]: any;
}

interface CustomOrganizationReferenceInputProps {
  source: string;
  label: string;
  alwaysOn?: boolean;
}

export const CustomOrganizationReferenceInput: React.FC<
  CustomOrganizationReferenceInputProps
> = ({ source, label }) => {
  const dataProvider = useDataProvider();
  const [options, setOptions] = useState<RecordWithKey[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .getList("organizations", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setOptions(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading) return <Loading />;
  if (error) return <div>Error loading options</div>;

  return (
    <AutocompleteInput
      source={source}
      choices={options.map(({ id, name, organizationKey }) => ({
        id: organizationKey,
        name: name + " - " + organizationKey,
      }))}
      label={label}
      alwaysOn
    />
  );
};
