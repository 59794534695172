import { FaFileInvoiceDollar } from "react-icons/fa";
import { PricingGroupList } from "../components/PricingGroups/PricingGroupList";
import {PricingGroupShow} from "../components/PricingGroups/PricingGroupShow";

const PricingGroups = {
  name: "pricing-groups",
  icon: FaFileInvoiceDollar,
  list: PricingGroupList,
  show: PricingGroupShow,
  menuLabel: "Pricing Groups",
};

export default PricingGroups;
