import {
    Datagrid,
    TextField,
    List,
    downloadCSV,
    useDataProvider, useListContext
} from "react-admin";



import CustomOrganizationInput from "../Members/CustomOrganizationInput";
import {MonthYearFilterInput} from "../Common/MonthYearInput";
import {Typography} from "@mui/material";
const jsonExport = require('jsonexport/dist');

const filter = [
    <CustomOrganizationInput label={"Organization"} source="organizationKey" resourceToFetch="organizations" alwaysOn />,
    <MonthYearFilterInput source={"transactionDate"} label={"Transaction Date"} alwaysOn />
    
];

const ShowTotalTicketGroups = (props: any) => {
    const { isLoading, data } = useListContext();
    if (isLoading) return null;
    const totalTicketGroups = data.reduce((acc: number, transaction: any) => acc + transaction.unitsCounted, 0);
    return <Typography variant="body1" sx={{p:2, fontWeight: "bold", opacity: .75}} gutterBottom>{`Total of ${totalTicketGroups} ticket groups were used for pricing during this period`}</Typography>;
}

export const BillingTransactionsList = () => {
    
    const dataProvider = useDataProvider();
    const exporter = async (transactions: any) => {
        
        const orgKey = transactions[0].organizationKey;
        const resp = await dataProvider.getList('organizations', {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'id', order: 'ASC' },
            filter: { organizationKey: orgKey }
        });
        
        const transactionsForExport = transactions.map((transaction: any) => {
            const { id, transactionDate, unitsCounted, ...transactionsForExport } = transaction; 
            transactionsForExport.organizationName = resp.data[0].name;
            transactionsForExport.ticketGroups = transaction.unitsCounted;
            transactionsForExport.date = transaction.transactionDate;
            return transactionsForExport;
        });
        jsonExport(transactionsForExport, {
            headers: ['organizationName', 'organizationKey', 'date', 'ticketGroups'], 
        }, (err: any, csv: any) => {
            downloadCSV(csv, 'billingTransactions'); 
        });
    };
    return (
            <>
                <List
                    filters={filter}
                    empty={false}
                    exporter={exporter}
                    sort={{field: "transactionDate", order: "ASC"}}
                    pagination={false}
                >
                    <ShowTotalTicketGroups />
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="transactionDate" label={"Date"}/>
                        <TextField source="unitsCounted" label={"Ticket Groups"}/>
                    </Datagrid>
                </List>
            </>
       
    )
};